
import {
  notificationMapGetters,
  notificationMapActions,
} from '@/store/modules/notificationModule'

export default {
  name: 'Notification',
  computed: {
    ...notificationMapGetters(),
    makeModal() {
      return this.notifications.some(x => x.isModal)
    },
  },
  methods: {
    ...notificationMapActions(),
    execAction(notificationAction, index: number) {
      notificationAction?.action?.()
      this.removeNotification(index)
    },
    closeNotification(index: number) {
      this.removeNotification(index)
    },
  },
}
